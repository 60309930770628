import React from 'react'
import { Container, Row, Col} from 'react-bootstrap';

import Change from '../../Assets/change.jpg';

import ShaliniMam from '../../Assets/ShaliniMam.jpeg'
import SuryaSir from '../../Assets/SuryaSir.jpeg';
import NandiMam from '../../Assets/NandiMam.jpg';

import Bicep from '../../Assets/Bicep.svg';

import * as Icon from 'react-bootstrap-icons';

import LightGallery from 'lightgallery/react';

// import styles
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-thumbnail.css';
// import plugins if you need
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgZoom from 'lightgallery/plugins/zoom';


import one from '../../Assets/1.jpg';
import two from '../../Assets/2.jpg';
// import three from '../../Assets/three.jpg';
import four from '../../Assets/4.jpg';
import five from '../../Assets/5.jpg';
import six from '../../Assets/6.jpg';
import seven from '../../Assets/7.jpg';
import eight from '../../Assets/8.jpg';
import nine from '../../Assets/9.jpg';
import ten from '../../Assets/10.jpg';


const About = () => {

  const onInit = () => {
    console.log('lightGallery has been initialized');
};


  return (
    <>
    <div className='about'>

        <Container fluid className='header'>
            <Row className='h-100 justify-content-center text-center align-items-center'>
                <Col md={10} className='mt-5' data-aos="fade-up">
                <h1>ABOUT US</h1>
                <h4>The pack is group of people who are commited to deliver best !</h4>
                </Col>
            </Row>
        </Container>
        
        {/*---- Vision & Mission Section ----*/}
        <Container className='vision mt-5 mb-5' id='vision'>
          <Row className='justify-content-center'>
            <div className='heading text-center' data-aos="fade-up">WHAT WE EVERYDAY DREAM FOR ?</div>

            <Row className='mt-3 justify-content-center'>
              <Col md={5}>
                <img src={Change} className='img-fluid' alt="Our Vision"/>
              </Col>
              <Col md={6} className='d-flex justify-content-center text-center align-items-center px-4 px-md-0' data-aos="fade-up">
                <div className='box vision-left'>
                <h3><Icon.Fire className='me-2'/>OUR VISION</h3>
                <p className='mb-1'><strong>"A step towards healthy world, physically & mentally."</strong></p>
                <p className='mb-0'>Our everyday vision is to make people happy and healthiest. Our each sessions helps to gain health and wellbeing.</p>
                  </div>
              </Col>
              </Row>

              <Row className='d-flex flex-column-reverse flex-md-row justify-content-center mt-4 mt-md-3'>
              <Col md={6} className='d-flex justify-content-center text-center align-items-center px-4 px-md-0' data-aos="fade-up">
                <div className='box mission-right'>
                <h3><Icon.Award className='me-2'/>OUR MISSION</h3>
                <p className='mb-1'><strong>"On a mission to build a healthy tribe"</strong></p>
                <p className='mb-0'>Our mission is to build to community of like-minded people, who help each other to provide a sense of purpose. We work continuously to grow our community.</p>
                </div>
              </Col>
              <Col md={5}>
                <img src={Change} className='img-fluid' alt="Our Vision"/>
              </Col>
              </Row>
          </Row>
          <Row>
          </Row>
        </Container>

         {/*---- OUR TEAM Section ----*/}
        <Container className='team'>
          <Row className='gy-3 gy-md-4'>
            <h1 className='text-center mb-2' data-aos="fade-up">OUR EXPERT TEAM</h1>
            <Col md={3} className='mb-3 mb-md-0'>
            <div className='newsCard news-Slide-up' data-aos="fade-up" data-aos-delay='100'>
            <img src={ShaliniMam} className='img-fluid' alt="Uday Sir" />
           <div className='newsCaption'>
           <div className='newsCaption-title mb-2 d-flex align-items-center'>
           <div className='img-bg me-3'><img src={Bicep} className="thepack-icon" alt="Bicep" width={25}/></div>
            <div>
              <h5 className='mb-1'>Shalini Pidiha</h5>
              <h6 className='mb-0'>Founder & Director</h6>
            </div>
            </div>
            
            <div className='newsCaption-content'>
              <p className='mb-2'>An <span>MBA</span> , with <span>k-11 ISSA</span> (International Sport Science Association) Certified Fitness Trainer.</p>
              <p>An enterprenuer by soul and following fitness by passion.</p> 
           </div>

        </div>
       </div>
       </Col>

            {/* <Col md={3} className='mb-3 mb-md-0'>
            <div className='newsCard news-Slide-up' data-aos="fade-up" data-aos-delay='100'>
            <img src={UdaySir} className='img-fluid' alt="Uday Sir" />
           <div className='newsCaption'>
           <div className='newsCaption-title mb-2 d-flex align-items-center'>
           <div className='img-bg me-3'><img src={Bicep} className="thepack-icon" alt="Bicep" width={25}/></div>
            <div>
              <h5 className='mb-1'>Uday Birmal</h5>
              <h6 className='mb-0'>Operation Head</h6>
            </div>
            </div>
            
            <div className='newsCaption-content'>
              <p className='mb-2'>Mr. Uday sir has <span>15+</span> years of experience in fitness training and operation.</p>
              <p><span>K-11</span> and golds gym university certified expert trainer.</p> 
           </div>

        </div>
       </div>
            </Col> */}

            {/* <Col md={3} className='mb-3 mb-md-0'>
            <div className='newsCard news-Slide-up' data-aos="fade-up" data-aos-delay='200'>
            <img src={SuryaSir} className='img-fluid' alt="Uday Sir" />
           <div className='newsCaption'>
           <div className='newsCaption-title mb-2 d-flex align-items-center'>
           <div className='img-bg me-3'><img src={Bicep} className="thepack-icon" alt="Bicep" width={25}/></div>
            <div>
              <h5 className='mb-1'>Suryakant Matre</h5>
              <h6 className='mb-0'>Fitness Coach</h6>
            </div>
            </div>
            <div className='newsCaption-content'>
              <p className='mb-2'>Mr. Suryakant sir has <span>15+</span> years of experience in fitness training.</p>
              <p><span>K-11</span>, Talwalkar and golds gym university certified expert trainer.</p> 
           </div>
        </div>
       </div>
            </Col> */}

            {/* <Col md={3} className='mb-3 mb-md-0'>
            <div className='newsCard news-Slide-up' data-aos="fade-up" data-aos-delay='300'>
            <img src={AtulSir} className='img-fluid' alt="Uday Sir" />
           <div className='newsCaption'>
           <div className='newsCaption-title mb-2 d-flex align-items-center'>
           <div className='img-bg me-3'><img src={Bicep} className="thepack-icon" alt="Bicep" width={25}/></div>
            <div>
              <h5 className='mb-1'>Atul Sharma</h5>
              <h6 className='mb-0'>Fitness Coach</h6>
            </div>
            </div>
            <div className='newsCaption-content'>
              <p className='mb-2'>Mr. Atul sir has <span>8+</span> years of experience in fitness training.</p>
              <p><span>K-11</span> and personal training & nutrition certified. Also trained in <span>kettlebell</span> and <span>Olympic Lifting.</span></p> 
           </div>
        </div>
       </div>
            </Col> */}

            <Col md={3} className='mb-3 mb-md-0'>
            <div className='newsCard news-Slide-up' data-aos="fade-up" data-aos-delay='400'>
            <img src={NandiMam} className='img-fluid' alt="Uday Sir" />
           <div className='newsCaption'>
           <div className='newsCaption-title mb-2 d-flex align-items-center'>
           <div className='img-bg me-3'><img src={Bicep} className="thepack-icon" alt="Bicep" width={25}/></div>
            <div>
              <h5 className='mb-1'>Dr. Nandi Dave</h5>
              <h6 className='mb-0'>Fitness Coach</h6>
            </div>
            </div>
            <div className='newsCaption-content'>
              <p className='mb-2'>Ms. Nandi mam is <span>doctor</span> by profession and <span>trainer</span> by passion.</p>
              <p>The <span>passion driven</span> fitness enthusiast and certified personal trainer.</p> 
           </div>
        </div>
       </div>
            </Col>

            {/* <Col md={3} className='mb-3 mb-md-0'>
            <div className='newsCard news-Slide-up' data-aos="fade-up" data-aos-delay='500'>
            <img src={AjinkyaSir} className='img-fluid' alt="Uday Sir" />
           <div className='newsCaption'>
           <div className='newsCaption-title mb-2 d-flex align-items-center'>
           <div className='img-bg me-3'><img src={Bicep} className="thepack-icon" alt="Bicep" width={25}/></div>
            <div>
              <h5 className='mb-1'>Ajinkya Palkar</h5>
              <h6 className='mb-0'>Sales Team Lead</h6>
            </div>
            </div>
            <div className='newsCaption-content'>
              <p className='mb-2'>Mr. Ajinkya sir has <span>4+</span> years experienced in sales and finance planning.</p>
              <p>He manages all the fees section and financial terms.</p> 
           </div>
        </div>
       </div>
            </Col> */}
          </Row>

       
        </Container>




<Container className='mt-5 mb-5 pb-5 gallery'>
 
  <Row data-aos="fade-up" data-aos-delay='200'>
  <h1 className='text-center'>OUR GALLERY</h1>
    <Col md={12}>
    <LightGallery
                onInit={onInit}
                speed={500}
                plugins={[lgThumbnail, lgZoom]}
                elementClassNames="NewClass">

 
                    <a href={one}>
                    <img alt="The Pack Fitness & Grossfit" src={one} />
                       </a>
                       <a href={six}>
                    <img alt="The Pack Fitness & Grossfit" src={six} />
                       </a>
                       <a href={two}>
                    <img alt="The Pack Fitness & Grossfit" src={two} />
                       </a>
                       <a href={seven}>
                    <img alt="The Pack Fitness & Grossfit" src={seven} />
                       </a>
                       <a href={eight}>
                    <img alt="The Pack Fitness & Grossfit" src={eight} />
                       </a>
                       <a href={four}>
                    <img alt="The Pack Fitness & Grossfit" src={four} />
                       </a>
                       <a href={five}>
                    <img alt="The Pack Fitness & Grossfit" src={five} />
                       </a>
                       {/* <a href={three}>
                    <img alt="The Pack Fitness & Grossfit" src={three} />
                       </a> */}
                       <a href={nine}>
                    <img alt="The Pack Fitness & Grossfit" src={nine} />
                       </a>
                       <a href={ten}>
                    <img alt="The Pack Fitness & Grossfit" src={ten} />
                       </a>
                
                
            </LightGallery>
    </Col>
  </Row>

</Container>


        <div className='my-5'>

        </div>

        </div>
   
    </>
  )
}

export default About;