import React, {useState} from 'react';
import { Carousel, Col, Container, Row, Card, Accordion} from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';

import Banner1 from '../../Assets/AboutBg.jpg';
import Banner2 from '../../Assets/Banner2.jpg';
import Banner3 from '../../Assets/Banner3.jpg';

import MobileModel1 from '../../Assets/MobileBanner-1.jpg';
import MobileModel2 from '../../Assets/MobileBanner-2.jpg';
import MobileModel3 from '../../Assets/MobileBanner-3.jpg';


import TradeMill from '../../Assets/TradeMill.svg';
import Juice from '../../Assets/Juice.svg';
import Dumbel from '../../Assets/Dumbel.svg';
import Heart from '../../Assets/Heart.svg';

import Beast from '../../Assets/Beast.jpg';
import Dumbell2 from '../../Assets/Dumbell2.svg';
import Brain from '../../Assets/Brain.svg';
import Bottle from '../../Assets/Bottle.svg';
import Much from '../../Assets/Much.svg';

import Test1 from '../../Assets/Test1.jpg';
import Test2 from '../../Assets/Test2.jpg';
import Test3 from '../../Assets/Test3.jpg';
import Test4 from '../../Assets/Test4.jpeg';
import Test5 from '../../Assets/Test5.jpg';
import Test6 from '../../Assets/Test6.jpg';
import Test7 from '../../Assets/Test7.jpeg';


import '../../App.css';


const Home = () => {

  // --- States For Handling Carousels ----
  const [index, setIndex] = useState(0);
   const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  return (
    <>
    <div>
      <Container fluid className='p-0' style={{overflow:'hidden'}}>
        <Row>
    <Carousel activeIndex={index} onSelect={handleSelect}>
      <Carousel.Item>
       <div className='banner' style={{backgroundImage:`url(${Banner1})`}}>
        <Row className='h-100 w-100 d-flex justify-content-center align-items-center'>
          <Col md={6} className='text-center'>
            <h1>TOGETHER WE CAN !</h1>
          </Col>
        </Row>
       </div>

       <div className='mobile-banner' style={{backgroundImage:`url(${MobileModel1})`}}>
        <Row className='h-100 w-100'>
          <Col className='MobileModel'>
            <div>
            <h1>TOGETHER</h1>
            <h1>WE CAN !</h1>
            </div>
          </Col>
        </Row>
       </div>

        {/* <div className='banner-img-mobile'>
          <Row className='h-100'>
            <Col className='MobileModel' style={{backgroundImage:`url(${MobileModel1})`}}>
            <div>
              <h1>BUILD</h1>
              <h1>YOUR</h1>
              <h1>DREAM BODY !</h1>
              <p className='text-center'>Our professionals knows exactly how to transfrom you into your dream body !</p>
              </div>
            </Col>
          </Row>
          </div> 
          
          <div className='banner-img' style={{backgroundImage:`url(${MobileModel1})`}}>
          <Row className='h-100'>
            <Col md={6}>
              <img src={Model_1} className='img-fluid' alt="Model-1"/>
            </Col>
            <Col md={5} className='d-flex justify-content-center align-items-center'>
              <div>
              <h1>BUILD</h1>
              <h1>YOUR</h1>
              <h1>DREAM BODY !</h1>
              <p>Our professionals knows exactly how to transfrom you into your dream body !</p>
              </div>
            </Col>
          </Row>
        </div> */}
       
      </Carousel.Item>

      <Carousel.Item>
      <div className='banner' style={{backgroundImage:`url(${Banner2})`}}>
        <Row className='h-100 w-100 d-flex justify-content-center align-items-center'>
          <Col md={6} className='text-center'>
            <h1>KNOWLEDGE IS POWER !</h1>
          </Col>
        </Row>
       </div>

       <div className='mobile-banner' style={{backgroundImage:`url(${MobileModel2})`}}>
        <Row className='h-100 w-100'>
          <Col className='MobileModel text-center'>
            <div>
            <h1>KNOWLEDGE</h1>
            <h1>IS POWER !</h1>
            </div>
          </Col>
        </Row>
       </div>

      </Carousel.Item>
      <Carousel.Item>
      <div className='banner' style={{backgroundImage: `url(${Banner3})` }}>
        <Row className='h-100 w-100 d-flex justify-content-center align-items-center'>
          <Col md={8} className='text-center'>
            <h1>BUILDING A COMMUNITY !</h1>
          </Col>
        </Row>
       </div>

       <div className='mobile-banner' style={{backgroundImage:`url(${MobileModel3})`}}>
        <Row className='h-100 w-100'>
          <Col className='MobileModel text-center'>
            <div>
            <h1>BUILDING</h1>
            <h1>A COMMUNITY !</h1>
            </div>
          </Col>
        </Row>
       </div>
      </Carousel.Item>
      {/* <Carousel.Item>
      <div className='banner-img'>
        <Row className='h-100'>
          <div className='d-flex justify-content-center align-items-center h-100'>
            <h1>New</h1>
          </div>
          </Row>
        </div>
        <div className='banner-img-mobile'>
        <Row className='h-100'>
          <div className='d-flex justify-content-center align-items-center h-100'>
            <h1>New</h1>
          </div>
          </Row>
        </div>
      </Carousel.Item> */}
    </Carousel>
      </Row>
    </Container>
  </div>

{/* -------------  The Pack Card Section ------------------*/}
     <Container className='mt-5 thepack'>
     <Row>
      <div className='mb-4' data-aos="fade-up">
         <h1 className='text-center'>" THE PACK "</h1>
         <h5 className='text-center'>Where health, beauty & fitness meets together !</h5>
      </div>
       <Col md={3} className='mb-4 md-mb-0'>
        <Card data-aos="fade-up" data-aos-delay="100">
         <div className='img-bg mx-auto'>
              <img src={TradeMill} className="thepack-icon" alt="icon"/>
            </div>
          <h4>Modern equipment</h4>
          <p>We have extensive range of state of art equipments that will help you to meet your goals. </p>
        </Card>
       </Col>
       <Col md={3} className='mb-4 md-mb-0'>
        <Card data-aos="fade-up" data-aos-delay="200">
        <div className='img-bg mx-auto'>
          <img src={Juice} className="thepack-icon" alt="icon"/>
         </div>
          <h4>Healthy nutrition plan</h4>
          <p>We have team of experts onboard to meet all your nutritional needs.</p>
        </Card>
       </Col>
       <Col md={3} className='mb-4 md-mb-0'>
        <Card data-aos="fade-up" data-aos-delay="300">
        <div className='img-bg mx-auto'>
          <img src={Dumbel} className="thepack-icon" alt="icon"/>
          </div>
          <h4>Professional training</h4>
          <p>We have team of experienced & certified trainers, Who are dedicated to serve you best.</p>
        </Card>
       </Col>
       <Col md={3} className='mb-4 md-mb-0'>
        <Card data-aos="fade-up" data-aos-delay="400">
        <div className='img-bg mx-auto'>
          <img src={Heart} className="thepack-icon" alt="icon"/>
          </div>
          <h4>Unique to your needs</h4>
          <p>You name it, we have it..! We are always open to listen your requirements and needs.</p>
        </Card>
       </Col>
     </Row>
   </Container> 

{/* -------------  The Pack Card Section ------------------*/}
   <Container className='mt-5 Beast'>
    <Row data-aos='fade-up'>
      <Col md={6} className='mb-3 md-mb-0'>
        <img src={Beast} className='img-fluid' alt="Beast" style={{borderRadius:'20px'}}/>
      </Col>
      <Col md={6} className='d-flex justify-content-center align-items-center'>
        <div>
        <div>
        <h1>If I can, then you can !</h1>
        <p>Join the pack and start your every morning with gaining muscles and energy for you valuable day. Healthy body host a healthy mind. Let that mind help you  grow muscles and loose fat.</p>
        <p>So, this is <strong>tour of your journey.</strong></p>
        </div>
        <Row className='gy-4'>
          <Col md={6}>
          <div className='d-flex justify-content-start align-items-center' data-aos="fade-up" data-aos-delay="100">
            <img src={Dumbell2} className='me-3' width={80} height={80} alt="Dumbel"/>
            <h6 className='mb-0'>Goal oriented workout.</h6>
          </div>
          </Col>
          <Col md={6}>
          <div className='d-flex justify-content-start align-items-center' data-aos="fade-up" data-aos-delay="200">
            <img src={Brain} className='me-3' width={80} height={80} alt="Brain"/>
            <h6 className='mb-0'>Mental Support & Motivation.</h6>
          </div>
          </Col>
          <Col md={6}>
          <div className='d-flex justify-content-start align-items-center' data-aos="fade-up" data-aos-delay="300">
            <img src={Bottle} className='me-3' width={80} height={80} alt="Bottle"/>
            <h6 className='mb-0'>Healthy Diet & Habits.</h6>
          </div>
          </Col>
          <Col md={6}>
          <div className='d-flex justify-content-start align-items-center' data-aos="fade-up" data-aos-delay="400">
            <img src={Much} className='me-3' width={80} height={80} alt="Much"/>
            <h6 className='mb-0'>Much More Fun Based Activities.</h6>
          </div>
          </Col>
        </Row>
        </div>
      </Col>
    </Row>
</Container>


{/* -------------  The Pack Testimonials Section ------------------*/}
<div className='Testimonials'>
<Container className='mt-5 pt-5 pb-4'>
    <Row className='justify-content-center text-center' data-aos="fade-up">
          <h5>Testimonials</h5>
          <h1>What our clients say's</h1>
      <Col md={12} className='mt-3'>
        <Carousel>
          <Carousel.Item>
            <img src={Test1} className='img-fluid' style={{width:'200px', height:'200px'}} alt='Testimonials-1'/>
            <div className='pt-4 pb-5 w-75 mx-auto'>
            <h3 className='mb-0'>Pramod Ghodke</h3>
            <p className='mb-0'><Icon.Briefcase className='me-2' size={20}/>Founder at Techvines Studios</p>
            <p className='mt-3'>" Due to my passion of Coding and UI/UX design, i always need to work with lot of energy and sometimes for lots of hours sitting at one chair. So i used to feel low at some point. But, from the day i joined pack, Trainers really helped me lot to improve my posture, energy, health and mental strength too. So now i am following my passion with lot of energy and supercharged mode always. Thanks to the Pack !." </p>
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <img src={Test2} style={{width:'200px', height:'200px'}} className='img-fluid' alt='testimonials-2'/>
            <div className='pt-4 pb-5  w-75 mx-auto'>
            <h3 className='mb-0'>Sanjay Rathod</h3>
            <p className='mb-0'><Icon.Briefcase className='me-2' size={20}/>Fashion Designer</p>
            <p className='mt-3'>"I have recently joined the gym. It is very hygienic and clean, also have a friendly and cooperative atmosphere. It has very advanced level of machineries. The overall interior and management of equipment, it's position and all is superb. This is the first gym I saw, that has a good management. I personally thank to shalini pidiha mam to maintain a good discipline and all.."</p>
            </div>
          </Carousel.Item>

          <Carousel.Item>
            <img src={Test3} style={{width:'200px', height:'200px'}} className='img-fluid' alt='testimonials-2'/>
            <div className='pt-4 pb-5  w-75 mx-auto'>
            <h3 className='mb-0'>Sayali Waghmare</h3>
            <p className='mb-0'><Icon.Briefcase className='me-2' size={20}/>Model & Actor</p>
            <p className='mt-3'>"For me dance and modeling is passion, working out at pack really help me shine & glow everytime while i perform anything. The Pack is one dream place for me where i can workout for my body and practice dance for my soul. So, thanks to trainers and team to boost me and help me to better than my past everyday."</p>
            </div>
          </Carousel.Item>

          <Carousel.Item>
            <img src={Test7} style={{width:'200px', height:'200px'}} className='img-fluid' alt='testimonials-3'/>
            <div className='pt-4 pb-5 w-75 mx-auto'>
            <h3 className='mb-0'>Sachin Iparkar</h3>
            <p className='mb-0'><Icon.Briefcase className='me-2' size={20}/>Business at MNC</p>
            <p className='mt-3'>"I am working with MNC based company and company work running in three shifts. 
Whenever I visit The Pack Gym  and I discuss this issue with gym trainers they allow me to work out as per my shift schedule. Nobody can adjust this in other gyms. so that's The Pack Gym is unique.
 I joined this gym for my fitness, strength and with the help of gym trainers I improved it 
Day by day i am getting better n better"</p>
            </div>
          </Carousel.Item>

          <Carousel.Item>
            <img src={Test4} style={{width:'200px', height:'200px'}} className='img-fluid' alt='testimonials-3'/>
            <div className='pt-4 pb-5 w-75 mx-auto'>
            <h3 className='mb-0' >Deepak Rathod</h3>
            <p className='mb-0'><Icon.Briefcase className='me-2' size={20}/>Distributor</p>
            <p className='mt-3'>"I am Deepak Rathour as a member of THE PACK FITNESS💪🏻, I am so grateful that i became a member of this gym. This friendly behaviour of everyone feels like family to me. It has been nearly 6 month's since I've join this gym but it feels like a whole year have been passed. Thanks to Shalini mam for her efforts that brings good descipline and perfect management."</p>
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <img src={Test5} style={{width:'200px', height:'200px'}} className='img-fluid' alt='testimonials-3'/>
            <div className='pt-4 pb-5 w-75 mx-auto'>
            <h3 className='mb-0' >Umesh Gupta</h3>
            <p className='mb-0'><Icon.Briefcase className='me-2' size={20}/>Developer</p>
            <p className='mt-3'>"AMAZING GYM, AMAZING WORKOUTS! The staff here is incredibly friendly and extremely qualified.They know how to push you to the limit in the best way possible with your workouts. You will not leave disappointed! Such a family vibe as soon as you walk in the doors.I highly recommend checking this gym out.😊"</p>
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <img src={Test6} style={{width:'200px', height:'200px'}} className='img-fluid' alt='testimonials-3'/>
            <div className='pt-4 pb-5 w-75 mx-auto'>
            <h3 className='mb-0' >Rahul Kulkarni</h3>
            <p className='mb-0'><Icon.Briefcase className='me-2' size={20}/>Brand Association & Film Marketing</p>
            <p className='mt-3'>"Have recently Joined the Gym THE PACK FITNESS... it's very neat & clean... it's different & very Stylish Fitenss Place... all equipment's are also in well operation manner & smooth...here m wishing all the best to respective management & all trainers all the best..."</p>
            </div>
          </Carousel.Item>
        </Carousel>
      </Col>
    </Row>
</Container>
</div>


{/* -------------  The Pack FAQ Section ------------------*/}
<div className='faq'>
<Container className='mt-5 mb-5 pb-5 pb-md-4'>
  <Row className='justify-content-center text-center'>
    <div data-aos="fade-up">
    <h5>FAQ</h5>
   <h1>Freqently Asked Questions ?</h1>
   </div>
   <Col xs={10} md={12} className='mt-3'>
    <Accordion defaultActiveKey="0">
      <Accordion.Item eventKey="0" data-aos="fade-up" data-aos-delay="100">
        <Accordion.Header>What is our gym timing for workout ?</Accordion.Header>
        <Accordion.Body>
          <Icon.ArrowRight className='me-2'/>Our gym is open for full day from 6 AM to 10 PM, so every gym lover can train accroding to work schedule and comfort.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1" data-aos="fade-up" data-aos-delay="200">
        <Accordion.Header>Do I will get personal assistant?</Accordion.Header>
        <Accordion.Body>
        <Icon.ArrowRight className='me-2'/>Yes, We have team of expert and certified trainers available to train you personally as well.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="2" data-aos="fade-up" data-aos-delay="300">
        <Accordion.Header>Do we have flexible membership plans ?</Accordion.Header>
        <Accordion.Body>
        <Icon.ArrowRight className='me-2'/>Yes, We have plans flexible as per your needs.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="3" data-aos="fade-up" data-aos-delay="300">
        <Accordion.Header>Will I get personalized diet plans ?</Accordion.Header>
        <Accordion.Body>
        <Icon.ArrowRight className='me-2'/>Yes sure, Our dietition will help you for all nutritional needs.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="4" data-aos="fade-up" data-aos-delay="300">
        <Accordion.Header>Will I get sport specific  training ?</Accordion.Header>
        <Accordion.Body>
        <Icon.ArrowRight className='me-2'/>Yes, We have special batches too for tranining sports-persons and Athletes.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="5" data-aos="fade-up" data-aos-delay="300">
        <Accordion.Header>Do we get rehabitation based training ?</Accordion.Header>
        <Accordion.Body>
        <Icon.ArrowRight className='me-2'/>Yes, We do have rehabitation & other personalised training as well.
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
    </Col>
    </Row>
  </Container>
</div>

  </>
  )
}
export default Home;