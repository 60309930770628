import React from 'react';
import { Outlet } from 'react-router-dom';
import Footer from '../Components/User/Footer';
import UserNav from '../Components/User/UserNav';

const UserLayout = () => {
  return (
    <>
    <UserNav/>
    <Outlet/>
    <Footer/>
    </>
  )
}
export default UserLayout;