import React from 'react'
import { Container,Row, Col, Button, Card } from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';

import strength from '../../Assets/strength.jpg';
import crossfit from '../../Assets/crossfit.JPG'; 
import spinning from '../../Assets/Spinning.jpg';
import proteinShop from '../../Assets/proteinShop.jpg';
import steam from '../../Assets/steam.jpg';
import dietition from '../../Assets/dietition.jpg';


const Services = () => {
  return (

    <div className='services'>

      <Container fluid className='header'>
          <Row className='d-flex justify-content-center align-items-center h-100'>
              <Col className='text-center' data-aos="fade-up">
              <h1 className='mb-0'>OUR SERVICES</h1>
              <h4>We are commited to provide you best fitness services.</h4>
              <a href='#services' style={{textDecoration:'none'}}><Button className='btn-enquire mt-3'><Icon.ArrowDownCircle className='me-2' 
              />Know More</Button></a>
              </Col>
          </Row>
      </Container>

      <Container className='my-5 services pb-5' id='services'>
        <Row className='text-center gy-4 gy-md-5'>
          <Col md={6} data-aos="fade-up">
            <Card>
            <img src={strength} className="img-fluid" alt="Strenth Training"/>
              <Card.Body>
                  <Card.Title><h2>Strength Training</h2></Card.Title>
              </Card.Body>
              </Card>
          </Col>

          <Col md={6} data-aos="fade-up">
            <Card>
            <img src={crossfit} className="img-fluid" alt="Cross-Fit"/>
              <Card.Body>
                  <Card.Title><h2>Cross-Fit</h2></Card.Title>
              </Card.Body>
              </Card>
          </Col>

          <Col md={6} data-aos="fade-up">
            <Card>
            <img src={spinning} className="img-fluid" alt="Spinning"/>
              <Card.Body>
                  <Card.Title><h2>Spinning</h2></Card.Title>
              </Card.Body>
              </Card>
          </Col>

          <Col md={6} data-aos="fade-up">
            <Card>
            <img src={proteinShop} className="img-fluid" alt="Protein Shop"/>
              <Card.Body>
                  <Card.Title><h2>Protein Shop</h2></Card.Title>
              </Card.Body>
              </Card>
          </Col>

          <Col md={6} data-aos="fade-up">
            <Card>
            <img src={steam} className="img-fluid" alt="Steam"/>
              <Card.Body>
                  <Card.Title><h2>Steam</h2></Card.Title>
              </Card.Body>
              </Card>
          </Col>

          <Col md={6} data-aos="fade-up">
            <Card>
            <img src={dietition} className="img-fluid" alt="Dietition"/>
              <Card.Body>
                  <Card.Title><h2>Dietitian</h2></Card.Title>
              </Card.Body>
              </Card>
          </Col>

          <h3>We have much more customised services for achieving your fitness, feel free to contact today !</h3>
        </Row>

      </Container>
    </div>
  )
}

export default Services;