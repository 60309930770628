import React, {useRef} from 'react';
import { Container, Row, Col, Form, FloatingLabel, Button } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import emailjs from '@emailjs/browser';
import * as Icon from 'react-bootstrap-icons';

const Contact = () => {

  const notify = () => toast.success("Enquiry Submitted Successfully!");
  const notsend = () => toast.error("Form not sent, Please try again");

  const form = useRef();

 const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_zfenldc', 'template_a1hvudo', form.current, 'OWzMq6nAsySEuGAPN')
      .then((result) => {
          console.log(result.text);
          notify()
        
      }, (error) => {
          console.log(error.text);
          notsend();
      
      });

     document.getElementById('new').reset();
  };


  return (


    <div className='contact'>
      <ToastContainer/>

        <Container fluid className='header'>
            <Row className='d-flex justify-content-center align-items-center h-100'>
                <Col className='text-center' data-aos="fade-up">
                <h1>CONTACT US</h1>
                <p>Our customer service team is 24/7 available for your queries !</p>
                <a href='#enquire' style={{textDecoration:'none'}}><Button className='btn-enquire'><Icon.ArrowDownCircle className='me-2' 
                />Enquire Now</Button></a>
                </Col>
            </Row>
        </Container>

        <Container className='my-5' id='enquire'>
        <h2 className='text-center mb-4'><Icon.EnvelopeFill className='me-2'/>Enquire Now</h2>
      
          <Row className='d-flex justify-content-center'>
 
            <Col md={5} data-aos="fade-up" data-aos-delay="100">
            <Form ref={form} onSubmit={sendEmail} className="contact-form" id="new">
            <FloatingLabel
               controlId="floatingInput"
               label="Name"
               className="mb-3 mb-md-4" >
               <Form.Control type="name" name="name" placeholder="name" required />
           </FloatingLabel>
           <FloatingLabel
               controlId="floatingInput"
               label="Mobile Number"
               className="mb-3 mb-md-4">
               <Form.Control type="tel" name="mobile" placeholder="Mobile Number" required/>
           </FloatingLabel>
           <FloatingLabel
               controlId="floatingInput"
               label="Email"
               className="mb-3 mb-md-4">
               <Form.Control type="email" name="email" placeholder="name@example.com" required/>
           </FloatingLabel>
           <div className='text-center'>
            <Button type="submit" className="btn-submit" data-aos="fade-up" data-aos-delay="500">Submit</Button>
            </div>
            </Form>
            </Col>

            <Col md={{ span: 6, offset: 1 }} className='text-center mt-5 mt-md-0 d-flex align-items-center' data-aos="fade-up" data-aos-delay="300">
              <div>
              <div>
              <Button href='tel:9359003311' className='btn-submit px-4 py-2'>
                <Icon.Telephone className='me-2'/>Click here to call us at 9359003311</Button>
              </div>

              <div className='mt-3'>
              <Button href='mailto:thepackfitness05@gmail.com' className='btn-submit px-4 py-2'>
                <Icon.Envelope className='me-2'/>Click here mail to us at thepackfitness05@gmail.com</Button>
              </div>

              <div className='mt-3'>
              <Button href='https://www.google.com/maps/place/The+Pack+Gym/@18.480599,73.803542,14z/data=!4m5!3m4!1s0x0:0x5b054ff12d268158!8m2!3d18.4805987!4d73.8035423?hl=en' target='_blank' className='btn-submit px-4 py-2'>
                <Icon.GeoAlt className='me-2'/>Visit us at Dangat Park, behind Mahindra showroom, besides swarnaa hotel, near warje bridge, Mumbai Bangalore, Highway, Warje, Pune</Button>
              </div>
              </div>
            </Col>


            
       {/* 
            <Col md={6}>
              <h6></h6>
            </Col> */}

            {/* <Col md={6} lg={4} data-aos="fade-up" data-aos-delay="200">
           <FloatingLabel controlId="floatingSelect" label="Select gender">
            <Form.Select aria-label="Floating label select example" name='gender' className='mb-3 mb-md-4' required>
              <option value="" selected disabled>What is your gender ?</option>
              <option value="Male">Male</option>
              <option value="Female">Female</option>
              <option value="Other">Other</option>
            </Form.Select>
           </FloatingLabel>

           <FloatingLabel controlId="floatingSelect" label="Yes or No">
           <Form.Select aria-label="Default select example" name='before' className='mb-3 mb-md-4' required>
           <option value="" selected disabled>Do you hit gym before ?</option>
           <option value="Yes">Yes</option>
              <option value="No">NO</option>
           </Form.Select>
           </FloatingLabel>
            </Col>
            
            <Col md={6} lg={4} data-aos="fade-up" data-aos-delay="300">
            <FloatingLabel controlId="floatingSelect" label="select month">
            <Form.Select aria-label="Floating label select example" name='duration' className='mb-3 mb-md-4' required>
              <option value="" selected disabled>How long you have been doing gym ?</option>
              <option value="0">0</option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
              <option value="6">6</option>
              <option value="8">7</option>
              <option value="8">8</option>
              <option value="9">9</option>
              <option value="10+">10+</option>
            </Form.Select>
           </FloatingLabel>

            <FloatingLabel label="Message" data-aos="fade-up" data-aos-delay="400">
           <Form.Control
             as="textarea"
             placeholder="Leave a comment here"
             name="message"
             className='mb-3 mb-md-4'/>
            </FloatingLabel>
            </Col> */}

            </Row>
            

        </Container>

        {/* ---- Google map location ---- */}
        <Container className='location mb-5 pb-5 pb-md-2'>
          <Row data-aos="fade-up">
            <h2 className='text-center mb-3'><Icon.GeoAltFill className='me-2'/>Our Location</h2>
            <Col md={12}>
            <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15136.270242854342!2d73.8035423!3d18.4805987!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x5b054ff12d268158!2sThe%20Pack%20Gym!5e0!3m2!1sen!2sin!4v1674586627037!5m2!1sen!2sin"
            style={{border:'0', borderRadius:'15px', width:'100%', height:'50vh'}} allowfullscreen="" loading="lazy" 
             referrerpolicy="no-referrer-when-downgrade"></iframe>
            </Col>
          </Row>
        </Container>

        <div className='mt-5'>

        </div>

    </div>
  )
}

export default Contact;