import React from 'react';
import { Row, Col, Button, Container } from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';
import { Link, useNavigate } from 'react-router-dom';
import logo from '../../Assets/logo.png';
const Footer = () => {
  const navigate = useNavigate();
  return (
    <div>
  
        <footer>
        <Container className='d-flex justify-content-center' data-aos="fade-up">
          <Row className='justify-content-center text-center footerTop py-3 w-75'>
            <Col md={6}>
              <h5 className='mb-3 mb-md-0'>Let's take healthy decision today</h5>
            </Col>
            <Col md={6}>
            <Button size="sm" className='btn-submit' onClick={()=>navigate('/contact')}>Contact Now</Button>
            </Col>
          </Row>
        </Container>

        <Container className='mt-5 mb-0'>
           <Row data-aos="zoom-in">
            <Col md={2} className='mb-3 mb-md-0 text-center'>
              <img src={logo} className='img-fluid mb-0 mb-md-3' alt='logo'/>
              <h5 className='d-none d-md-block'>" On a mission to build a healthy tribe "</h5>
            </Col>
            <Col md={2} className='mb-4 mb-md-0'>
              <h6><Icon.ArrowsMove className='me-2'/>SITEMAP</h6>
              <div className='sitemap ps-4 mt-3'>
              <Link to="/"><Icon.HouseUp className='me-2' size={20}/>Home</Link>
              <Link to='/services'><Icon.BagHeart className='me-2' size={20}/>Services</Link>
              <Link to='/about'><Icon.Buildings className='me-2' size={20}/>About Us</Link>
              <Link to='/contact'><Icon.TelephoneForward className='me-2' size={20}/>Contact Us</Link>
              </div>
            </Col>
            <Col md={2} className='mb-4 mb-md-0'>
              <h6><Icon.BookmarkHeart className='me-2'/>OUR SOCIALS</h6>
                <div className='sitemap ps-4 mt-3'>
                <a href="https://www.instagram.com/thepackgymcrossfit/" rel="noreferrer" target="_blank"><Icon.Instagram className='me-2' size={20}/>Instagram</a>
                <a href='https://www.facebook.com/thepackpune/' rel="noreferrer" target="_blank"><Icon.Facebook className='me-2' size={20}/>Facebook</a>
                <a href='https://www.youtube.com/' rel="noreferrer" target="_blank"><Icon.Youtube className='me-2' size={20}/>Youtube</a>
                </div>
            </Col>
            <Col md={6} className='mb-4 mb-md-0'>
            <h6><Icon.GeoAlt className='me-2'/>OUR ADDRESS</h6>
              <div className='sitemap ps-4 mt-3'>
              <p className='mb-0'><Icon.GeoAlt className='me-2' size={20}/>Dangat Park, behind Mahindra showroom, besides swarnaa hotel, near warje bridge, Mumbai Bangalore, Highway, Warje, Pune, Maharashtra 411058</p>
              <p className='mb-0'><Icon.Telephone className='me-2'/>9359003311 / 9665823101</p>
              <p className='mb-0'><Icon.Envelope className='me-2'/>thepackfitness05@gmail.com</p>
              </div>   
            </Col>
           </Row>
            <h6 className='text-center mt-3 mb-0'>&copy; All Right Reserved | {new Date().getFullYear()}</h6>
           </Container>
        </footer>
    </div>
  )
}

export default Footer