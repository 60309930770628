import React, {useState,useEffect} from 'react';
import { Link } from "react-router-dom";
import logo from '../../Assets/logo.png';
import * as Icon from 'react-bootstrap-icons';


const UserNav = () => {

  function setnav(){
    const mobilenav = document.querySelector(".mobile-nav");
    const toggle = document.querySelector(".mobile-nav-toggle");
  
    const visibility = mobilenav.getAttribute('data-visible' );
    if(visibility ==="false"){
        mobilenav.setAttribute("data-visible" , true);
        toggle.setAttribute("aria-expanded", true);
        document.querySelector("body").style.overflow='hidden';
    }
    else if(visibility === "true"){
        mobilenav.setAttribute("data-visible" , false);
        toggle.setAttribute("aria-expanded", false);
        document.querySelector("body").style.overflow='auto';
        
    }
  }


  
  useEffect(()=>{
    changeNavbarColor();
  },[])
  const [colorChange, setColorchange] = useState(false);
  const changeNavbarColor = () =>{
     if(window.scrollY >= 100){
       setColorchange(true);
     }
     else{
       setColorchange(false);
     }
  }
  window.addEventListener('scroll', changeNavbarColor);
  return (

    <nav className={colorChange ? 'navbarBlack' : 'navbarTransperent'}>
    <Link to="/"><img src={logo} alt="THE PACK" width={120}/></Link>
    <div className="mobile-nav-toggle" aria-controls='selectNav' aria-expanded="false"  onClick={setnav}>
        <div className='menu-btn__burger'></div>
    </div>
 
    <ul className="lg-nav">
    <Link to="/"><li>HOME</li></Link>
    <Link to="/services"><li>SERVICES</li></Link>
     <Link to="/about"><li>ABOUT US</li></Link>
    <Link to="/contact"><li>CONTACT US</li></Link>
    </ul>

    
    <ul id="selectNav" className="mobile-nav" data-visible="false">
    <Link to="/" style={{textDecoration:'none'}}><li className="active" onClick={setnav}><Icon.HouseUp className='me-3' size={30}/>HOME</li></Link>
    <Link to="/services" style={{textDecoration:'none'}}><li onClick={setnav}><Icon.BagHeart className='me-3' size={30}/>SERVICES</li></Link>
    <Link to="/about" style={{textDecoration:'none'}}><li onClick={setnav}><Icon.Buildings className='me-3' size={30}/>ABOUT US</li></Link>
    <Link to="/contact" style={{textDecoration:'none'}}><li onClick={setnav}><Icon.TelephoneForward className='me-3' size={30}/>CONTACT US</li></Link>
    </ul>
</nav>
  )
}

export default UserNav;