import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import UserLayout from "./Layout/UserLayout";
import Home from './Components/User/Home';
import Services from "./Components/User/Services";
import About from "./Components/User/About";
import Contact from "./Components/User/Contact";
import ScrollToTop from "./Components/ScrollToTop";



function App() {
  return (
    <>
    <BrowserRouter>
    <ScrollToTop/>
    <Routes>
      <Route path="/" element={<UserLayout/>}>
        <Route path="/" element={<Home/>}/>
        <Route path="/services" element={<Services/>}/>
        <Route path="/about" element={<About/>}/>
        <Route path="/contact" element={<Contact/>}/>
      </Route>

    </Routes>
    </BrowserRouter>
      
    </>
  );
}

export default App;
